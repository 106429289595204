

export const state = () => ({
  /*     product: {}, */
})

export const getters = {
  /*     selectedProduct: (state) => {
      return state.product
    }, */
}

export const mutations = {
  /*     SET_PRODUCT(state, product) {
      state.product = product
    }, */
}

export const actions = {
    async getPromocodes(){
        try {
            const codesQuery = await this.$fire.firestore.collection("promocodes").get();
            if(codesQuery.empty) throw new Error("No codes found");

            const codes = codesQuery.docs.map(doc=> { return {...doc.data(), id:doc.id, ref: doc.ref}})
            return Promise.resolve(codes)
        } catch (error) {
            return Promise.reject(error)
        }
    },
    async addCode({ commit, dispatch, state }, id){

    },
    async deleteCode({ commit, dispatch, state }, id){

    }
}