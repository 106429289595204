export const status = [
    {
        id : 0, 
        value: "Draft",
        color: "grey",
    },
    {
        id : 1, 
        value: "Awaiting Payment Confirmation",
        color: "info",
    },
    {
        id : 2, 
        value: "Processing",
        color: "blue darken-2",
    },
    {
        id : 3, 
        value: "Completed",
        color: "deep-purple darken-4",
    },
    {
        id : 7, 
        value: "Scheduled To Deliver",
        color: "teal darken-4",
    },
    {
        id : 4, 
        value: "Shipped",
        color: "teal",
    },
    {
        id : 5, 
        value: "Delivered",
        color: "success",
    },
    {
        id : 6, 
        value: "Cancelled",
        color: "red",
    },

]