export default async function ({ store, next, redirect, route, error }) {
  const l = ['/user/account', '/user/account/']

  const o = ['/login', '/login/']

  const adminRoutes = [
    '/admin/actions',
    '/admin/addbindingimages',
    '/admin/addcarouselimages',
    '/admin/addcovergraphic',
    '/admin/addhomecarouselimages',
    '/admin/addproduct',
    '/admin/addsubcategory',
    '/admin/changeprocessingtime',
    '/admin/createadminuser',
    '/admin/creategiftcertificate',
    '/admin/deploy',
    '/admin/editcheckoutnote',
    '/admin/editnewsbarmessage',
    '/admin/exportorders',
    '/admin/managegiftcertificates',
    '/admin/manageorders',
    '/admin/manageproductpriority',
    '/admin/managepromocodes',
    '/admin/orderdetails',
    '/admin/productmanagement',
  ]

  const adminRoutesTrailingSlash = adminRoutes.map(r=> `${r}/`);

  const allroutes = [...adminRoutes, ...adminRoutesTrailingSlash]

  const { uid, isAdmin } = store.getters['auth/authUser'];


  if (l.indexOf(route.path) !== -1 && !uid)
    redirect('/login/')

  if (o.indexOf(route.path) !== -1 && uid)
    redirect('/user/account/')

  if (allroutes.indexOf(route.path) !== -1 && !isAdmin)
    redirect('/')

  /*      if(whitelist.indexOf(route.path) === -1){
      error(
        {      
          message: 'access denied',
          statusCode: 403
        }
      );
    }  */
}