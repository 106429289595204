export const state = () => ({
  address: {},
})

export const getters = {
  /*     selectedProduct: (state) => {
      return state.product
    }, */
}

export const mutations = {
  /*     SET_PRODUCT(state, product) {
      state.product = product
    }, */
}

export const actions = {
  async addAddress({ commit, dispatch }, address) {
    try {
      const { uid } = this.getters['auth/authUser']
      const { arrayUnion } = this.$fireModule.firestore.FieldValue
      const dir = `users/${uid}`
      const pAdd = await this.$fire.firestore
        .doc(dir)
        .set({ addresses: arrayUnion(address) }, { merge: true })
      return Promise.resolve(pAdd)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async deleteUserAddress({ commit, dispatch }, address) {
    try {
      const { uid } = this.getters['auth/authUser']
      const { arrayRemove } = this.$fireModule.firestore.FieldValue
      const dir = `users/${uid}`
      const pAdd = await this.$fire.firestore
        .doc(dir)
        .update({ addresses: arrayRemove(address) })
      return Promise.resolve(pAdd)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getAddresses() {
    try {
      const { uid } = this.getters['auth/authUser']
      if(!uid) return;
      const dir = `users/${uid}`

      const pAdd = await this.$fire.firestore
        .doc(dir)
        .get()

      if(!pAdd.exists) return Promise.resolve([]);
      return Promise.resolve(pAdd.data().addresses)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getUserData(){
    try {
      const { uid } = this.getters['auth/authUser']
      if(!uid) return null;
      const dir = `users/${uid}`

      const pAdd = await this.$fire.firestore
        .doc(dir)
        .get()

      if(!pAdd.exists) return Promise.resolve({});
      const { addresses, ...firestoreUser} = pAdd.data();
      return Promise.resolve(firestoreUser)
    } catch (error) {
      return Promise.reject(error)
    } 
  },
}
