
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    freeShippingDetails: Object,
  },
  data() {
    return {
      thresholdAmount: 1,
      isFreeShippingEnabled: false,
    }
  },

  methods: {
    ...mapActions('cart', ['getUserCart', 'removeProductFromVuexCart']),
  },
  computed: {
    ...mapGetters('cart', ['productsInCart', 'cartLength']),
    calculateCartTotal() {
      const { productsInCart: cart } = this

      if (!cart?.length) return 0
      const total = cart.reduce((acc, cur) => {
        //if(cur.product.name === "Gift Card") return acc + (+cur.product.cost);
        return acc + +cur.itemTotal
      }, 0)

      return Number(total)
    },
    freeShippingAmtLeft() {
      const { thresholdAmount } = this.freeShippingDetails
      return thresholdAmount - this.calculateCartTotal
    },
    progressBarValue() {
      const { thresholdAmount } = this.freeShippingDetails
      return (this.calculateCartTotal / thresholdAmount) * 100
    },
    progressBarMessage() {
      if (this.freeShippingAmtLeft <= 0)
        return 'Free shipping will apply at checkout!'
      else
        return `${this.freeShippingAmtLeft.toFixed(
          2
        )} left to activate free shipping!`
    },
  },
}
