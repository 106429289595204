import slugify from 'slugify'

export const actions = {
  async adminGetAllProducts() {
    try {
      const { isAdmin } = this.getters['auth/authUser']
      if (!isAdmin)
        throw new Error(
          'Insufficent permissions to execute product collection view'
        )
      const l = await this.$fire.firestore.collection('products').get()

      const p =
        l.docs.map((doc) => {
          return { ...doc.data(), id: doc.id, ref: doc.ref }
        }) || []
      return p
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async adminGetAllProductsByPriority() {
    try {
      const { isAdmin } = this.getters['auth/authUser']
      if (!isAdmin)
        throw new Error(
          'Insufficent permissions to execute product collection view'
        )
      const l = await this.$fire.firestore
        .collection('products')
        .orderBy('priority', 'asc')
        .get()

      const p =
        l.docs.map((doc) => {
          return { ...doc.data(), id: doc.id, ref: doc.ref }
        }) || []
      return p
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async addProduct({ commit, dispatch }, product) {
    try {
      const { isAdmin } = this.getters['auth/authUser']
      if (!isAdmin)
        throw new Error('Insufficent permissions to execute product addition')

      const { name, category, specificImageFiles, subCategory, options, spreadTextCustomization } =
        product

      const common = name.toLowerCase()
      const commonCategory = category.toLowerCase()
      const commonSubCategory = subCategory?.toLowerCase() || ''
      const ref = this.$fire.firestore.collection('products').doc()

      //upload images
      const uploads = specificImageFiles.map((file) => {
        const path = `/products/${ref.id}/hq/${file.name}`
        const storage = this.$fire.storage.ref().child(path)

        const metadata = {
          customMetadata: {
            imageName: file.name,
            price: 0,
            value: file.name,
            id: Math.random() * 1000,
          },
        }
        return storage.put(file, metadata)
      })

      const optionUploads = options.map((option) => {
        const { file } = option
        const path = `/products/${ref.id}/options/hq/${file.name}`
        const storage = this.$fire.storage.ref().child(path)
        const metadata = {
          customMetadata: {
            imageName: file.name,
            price: option.price,
            value: option.name,
            id: option.id,
          },
        }
        return storage.put(file, metadata)
      })

      let spreadTextCustomizationPhotoUpload = [];
      if(spreadTextCustomization.value){
        if(spreadTextCustomization.sections.length){
          spreadTextCustomizationPhotoUpload = spreadTextCustomization.sections.map((section,index)=>{
            const { imageFile } = section;
            const path = `/products/${ref.id}/spreadTextCustomization/hq/${index}-${imageFile.name}`
            const storage = this.$fire.storage.ref().child(path)
            const metadata = {
              customMetadata: {
                imageName: imageFile.name,
                price: 0,
                value: imageFile.name,
                id: Math.random() * 1000,
              },
            }
            return storage.put(imageFile, metadata)
          })
        }
      }
      
      //wait for all the images to upload
      await Promise.all([...uploads, ...optionUploads,...spreadTextCustomizationPhotoUpload])

      const getOptionDownloadUrls = options.map((option) => {
        const { file } = option
        const path = `/products/${ref.id}/options/hq/${file.name}`
        const storage = this.$fire.storage.ref().child(path)
        const downloadUrl = storage.getDownloadURL()
        return downloadUrl
      })

      const getSpreadTextCustomizationPhotoDownloadUrls = spreadTextCustomization.sections.map((section,index)=>{
        const { imageFile } = section
        
        const path = `/products/${ref.id}/spreadTextCustomization/hq/${index}-${imageFile.name}`
        const storage = this.$fire.storage.ref().child(path)
        const downloadUrl = storage.getDownloadURL()
        return downloadUrl
      })
      console.info('[STORAGE] waiting for upload')

      //wait for all the download Urls
      const optionDownloadUrls = await Promise.all(getOptionDownloadUrls)

      const stcDownloadUrls = await Promise.all(getSpreadTextCustomizationPhotoDownloadUrls)

      //extract options from product
      const productOptions = product.options

      //delete image data from object
      delete product.options
      delete product.specificImages
      delete product.specificImageFiles
      delete product.coverImages
      delete product.coverFiles


      if(spreadTextCustomization.value){
        if(spreadTextCustomization.sections.length){
          spreadTextCustomizationPhotoUpload = spreadTextCustomization.sections.map((section,index)=>{
            delete product.spreadTextCustomization.sections[index].imageHq;
            delete product.spreadTextCustomization.sections[index].imageFile;
          })
        }
      }
      console.info('[FIRESTORE] waiting for firestore')
      //upload to Firestore
      product.dateAdded =
        this.$fireModule.firestore.FieldValue.serverTimestamp()
      product.linkName = slugify(common,{strict: true})
      product.linkCategory = slugify(commonCategory,{strict: true})
      product.linkSubCategory = slugify(commonSubCategory,{strict: true})
      product.isImageProcessingComplete = false
      product.priority = 0
      product.isFeatured = false
      product.cost = +product.cost

      //if(product.stockQuantity)
      //  product.stockQuantity = Number(product.stockQuantity) || 0 ;

      //if(product.stockQuantityAlert)
      // product.stockQuantityAlert = Number(product.stockQuantityAlert) || 0;

      const batch = this.$fire.firestore.batch()
      
      //add image urls for spread text customization
      product.spreadTextCustomization.sections.forEach((section,index)=>{
        product.spreadTextCustomization.sections[index].imageHq = stcDownloadUrls[index];
      })
      batch.set(ref, product)

      productOptions.forEach((option, index) => {
        const { id } = ref
        option.file = null
        option.image = optionDownloadUrls[index]
        if (option.stock) option.stock = +option.stock || 0

        if (option.stock)
          option.stockQuantityAlert = +option.stockQuantityAlert || 0
        const optionRef = this.$fire.firestore
          .collection(`products/${id}/options`)
          .doc()

        batch.set(optionRef, option)
      })

      await batch.commit()
      //add options
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateProduct({ commit, dispatch }, product) {
    try {
      const { isAdmin } = this.getters['auth/authUser']
      if (!isAdmin)
        throw new Error('Insufficent permissions to execute product update')
      const { name, id,specificImageFiles, category, subCategory, options } = product
      const productDir = `products/${id}`
      const ref = this.$fire.firestore.doc(productDir)
      const { serverTimestamp } = this.$fireModule.firestore.FieldValue
      const common = name.toLowerCase()
      const commonCategory = category.toLowerCase()
      const commonSubCategory = subCategory?.toLowerCase() || ''

      if (!id) throw new Error('Unable to update product. No ID found.')
      product.dateEdited = serverTimestamp()
      product.linkName = slugify(common,{strict: true})
      product.linkCategory = slugify(commonCategory,{strict: true})
      product.linkSubCategory = slugify(commonSubCategory,{strict: true})
      product.isImageProcessingComplete = false

      //upload images
      const uploads = specificImageFiles.map((file) => {
        const path = `/products/${id}/hq/${file.name}`
        const storage = this.$fire.storage.ref().child(path)

        const metadata = {
          customMetadata: {
            imageName: file.name,
            price: 0,
            value: file.name,
            id: Math.random() * 1000,
          },
        }
        return storage.put(file, metadata)
      })

      const optionUploads = options.map((option) => {
        
        const { file, optionRef } = option

        if(optionRef) return null; //this means this option has already been added to firestore
        const path = `/products/${id}/options/hq/${file.name}`
        const storage = this.$fire.storage.ref().child(path)
        const metadata = {
          customMetadata: {
            imageName: file.name,
            price: option.price,
            value: option.name,
            id: option.id,
          },
        }
        return storage.put(file, metadata)
      })

      //wait for all the images to upload
      await Promise.all([...uploads, ...optionUploads])

      const getOptionDownloadUrls = options.map((option) => {
        if(option.optionRef) return null; //this means this option has already been added to firestore
        const { file } = option
        const path = `/products/${id}/options/hq/${file.name}`
        const storage = this.$fire.storage.ref().child(path)
        const downloadUrl = storage.getDownloadURL()
        return downloadUrl
      })

      console.info('[STORAGE] waiting for upload')

      //wait for all the download Urls
      const optionDownloadUrls = await Promise.all(getOptionDownloadUrls)

      //extract options from product
      const productOptions = product.options
  
      //delete unserializable data from object
      delete product.dateAdded

      //delete image data from object
      delete product?.options
      delete product?.specificImages
      delete product?.specificImageFiles
      delete product?.coverImages
      delete product?.coverFiles
      console.info('[FIRESTORE] waiting for firestore')
      //upload to Firestore
      product.dateAdded =
        this.$fireModule.firestore.FieldValue.serverTimestamp()
      product.linkName = slugify(common,{strict: true})
      product.linkCategory = slugify(commonCategory,{strict: true})
      product.linkSubCategory = slugify(commonSubCategory,{strict: true})
      product.isImageProcessingComplete = false
      //product.priority = 0 //this is an update, don't change the product priority
      product.isFeatured = false
      product.cost = +product.cost

      //if(product.stockQuantity)
      //  product.stockQuantity = Number(product.stockQuantity) || 0 ;

      //if(product.stockQuantityAlert)
      // product.stockQuantityAlert = Number(product.stockQuantityAlert) || 0;

      const batch = this.$fire.firestore.batch()
      batch.update(ref, product)

      productOptions.forEach((option, index) => {
        option.file = null
        option.image = optionDownloadUrls[index] //TODO: why did I need to do this?
        if (option.stock) option.stock = +option.stock || 0

        if (option.stock)
          option.stockQuantityAlert = +option.stockQuantityAlert || 0
        const optionRef = this.$fire.firestore
          .collection(`products/${id}/options`)
          .doc()

        if(option.optionRef) batch.update( option.optionRef, option)
        if(!option.optionRef) batch.set(optionRef, option)

        // TODO: Delete the option
      })
      await batch.commit()
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteProduct({ commit, dispatch }, product) {
    try {
      const { id } = product
      const batch = this.$fire.firestore.batch()
      const delItemsDir = `productsDeleted/${id}`
      const itemDir = `products/${id}`
      const delItemsRef = this.$fire.firestore.doc(delItemsDir)
      const itemRef = this.$fire.firestore.doc(itemDir)
      batch.set(delItemsRef, product)
      batch.delete(itemRef)
      await batch.commit()
    } catch (e) {
      return Promise.reject(e)
    }
  },
}
