export const state = () =>({
    authUser:{
        uid: '',
        email: '',
        emailVerified: false,
        displayName: '',
        photoURL: '', // results in photoURL being undefined for server auth
        // use custom claims to control access (see https://firebase.google.com/docs/auth/admin/custom-claims)
        isAdmin: false,
    }
})

export const getters ={
    authUser: state => {
        return state.authUser;
      },
}

export const mutations ={
    ON_AUTH_STATE_CHANGED_MUTATION(state, { authUser, claims }) {
        if(!authUser)
            return;

        // you can request additional fields if they are optional (e.g. photoURL)
        const { uid, email, emailVerified, displayName, photoURL } = authUser
        state.authUser = {
          uid,
          displayName,
          email,
          emailVerified,
          photoURL: photoURL || null, // results in photoURL being null for server auth
          // use custom claims to control access (see https://firebase.google.com/docs/auth/admin/custom-claims)
          isAdmin: claims.admin
        }
      },
    SET_USER(state, { user }) {
        // you can request additional fields if they are optional (e.g. photoURL)
        const { uid, email, emailVerified, displayName, photoURL, claims } = user
        
        state.authUser = {
          uid,
          displayName,
          email,
          emailVerified,
          photoURL: photoURL || null, // results in photoURL being null for server auth
          // use custom claims to control access (see https://firebase.google.com/docs/auth/admin/custom-claims)
          isAdmin: claims.admin
        }

        
    }
}

export const actions ={
    cleanupAction({ commit }){
        const user = {
            uid: '',
            email: '',
            emailVerified: false,
            displayName: '',
            photoURL: '', // results in photoURL being undefined for server auth
            // use custom claims to control access (see https://firebase.google.com/docs/auth/admin/custom-claims)
            claims:{}
        };
        commit('SET_USER', {
            user:{
              ...user
            }
          })
    },
    async onAuthStateChangedAction({ commit, dispatch }, { authUser, claims }) {
        if (!authUser) {
          await dispatch('cleanupAction')
          return this.$router.push({path: '/'});
        }
        // you can request additional fields if they are optional (e.g. photoURL)
        const { uid, email, emailVerified, displayName, photoURL } = authUser
        commit('SET_USER', {
          user:{
            uid, email, emailVerified, displayName, photoURL, claims
          }
        })
        if(claims.admin) return this.$router.push({path: '/admin/actions/'});
        this.$router.push({path: '/user/account/'});
      },
    async sendResetEmailVuex({ commit, dispatch ,state}){
      try {
        const reset = this.$fire.auth.sendPasswordResetEmail(state.authUser.email);
        return reset;
      } catch (error) {
        Promise.reject(error)
      }
    },
}