import { status } from '@/constants/status'

export const state = () => ({
  /*     product: {}, */
})

export const getters = {
  /*     selectedProduct: (state) => {
      return state.product
    }, */
}

export const mutations = {
  /*     SET_PRODUCT(state, product) {
      state.product = product
    }, */
}

export const actions = {
  async getAllOrders({ commit, dispatch, state }, statusID) {
    try {
      if (!statusID) statusID = true
      const allOrdersRef = this.$fire.firestore
        .collection('orders')
        .where('status.id', '==', statusID)
        .orderBy('dateAdded', 'desc')
      const allOrdersCol = await allOrdersRef.get()
      if (allOrdersCol.empty) return []
      const allOrdersDocs = allOrdersCol.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
          ref: doc.ref,
          isEmailSending: false,
          isForceEmailSending: false,
        }
      })
      return Promise.resolve(allOrdersDocs)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
