export const categories = [
  {
    value: 'Home',
    price: 0,
    id: 0,
    disabled: false,
    route: '/',
  },
  {
    value: 'Yearly Planners',
    price: 0,
    id: 1,
    disabled: false,
  },
  {
    value: 'Specialty Products',
    price: 0,
    id: 2,
    disabled: false,
  },
  {
    value: 'Journals',
    price: 0,
    id: 3,
    disabled: false,
  },
  {
    value: 'Notebooks',
    price: 0,
    id: 4,
    disabled: false,
    route: '/notebooks/',
  },
  {
    value: 'Notepads',
    price: 0,
    id: 5,
    disabled: false,
    route: '/notepads/',
  },
  {
    value: 'Gifts & Bundles',
    price: 0,
    id: 6,
    disabled: false,
  },
  {
    value: 'Collections',
    price: 0,
    id:7,
    disabled: false,
  },
  {
    value: 'Accessories',
    price: 0,
    id: 8,
    disabled: false,
  },
/*   {
    value: 'Custom Orders',
    price: 0,
    id: 6,
    disabled: false,
    route: '/custom-orders/',
  }, */
]
