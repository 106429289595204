export const state = () => ({
  /*     product: {}, */
})

export const getters = {
  /*     selectedProduct: (state) => {
        return state.product
      }, */
}

export const mutations = {
  /*     SET_PRODUCT(state, product) {
        state.product = product
      }, */
}

export const actions = {
  async saveProcessingTime({ commit, dispatch, state }, { min, max }) {
    try {
      const time = {
        min: Number(min),
        max: Number(max),
      }
      await this.$fire.firestore.doc('admin/processingtime').set(time)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getProcessingTime() {
    try {
      const timeDoc = await this.$fire.firestore
        .doc('admin/processingtime')
        .get()
      if (!timeDoc.exists)
        throw new Error('No exisitng data found for processing time')
 
      return Promise.resolve(timeDoc.data())
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async addCode({ commit, dispatch, state }, id) {},
  async deleteCode({ commit, dispatch, state }, id) {},
}
