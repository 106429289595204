export const state = () => ({
  productsInCart: process.browser?  JSON.parse(localStorage.getItem('cart')): [],
  deletedProducts: [],
  cartListener: '',
})

export const getters = {
  productsInCart: (state) => {
    return state.productsInCart
  },
  cartListener: (state) => {
    return state.cartListener
  },
  cartLength:(state) =>{
    return state.productsInCart.length;
  },
  cartLastItem: (state)=>{
    const { productsInCart: p} = state;
    const l = p.length
    if(!l) return null;
    
    return state.productsInCart[l-1];
  },
}

export const mutations = {
  ADD_PRODUCT_TO_CART(state, product) {
    const cart = [... state.productsInCart]; //create scoped copy of products in cart
    cart.push({...product});

   
    if(process.browser){
      const stringifiedCart = JSON.stringify(cart);
      localStorage.setItem('cart',stringifiedCart);
    }

    state.productsInCart = cart;
  },
  UPDATE_CART(state, cart) {
    const stringifiedCart = JSON.stringify(cart);
    if(process.browser)
      localStorage.setItem('cart',stringifiedCart);
    
      
    state.productsInCart = cart
  },
}

export const actions = {
  async getUserCart({ commit, state }) {
   
    try {
      const { uid } = this.getters['auth/authUser'];
      if(!uid) {
        if(!process.browser) return [];
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        return commit("UPDATE_CART",[...cart]);
      };
      
      const dir = `users/${uid}/cart`;

      const pAdd = await this.$fire.firestore
        .collection(dir)
        .onSnapshot((snapshot) => {
          const p = snapshot.docs.map((doc) => {
            return { ...doc.data(), cartId: doc.id }
          })

          commit("UPDATE_CART",[...p]);
        })
      return Promise.resolve(pAdd)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async addToVuexCart({ commit, dispatch }, product) {

    try {
     

      const { uid } = this.getters['auth/authUser'];
      if(!uid) return  commit('ADD_PRODUCT_TO_CART', product); //update the local state first;

      const dir = `users/${uid}/cart`
      const pAdd = await this.$fire.firestore
        .collection(dir)
        .add({ ...product }, { merge: true })
      
        commit('ADD_PRODUCT_TO_CART', product); //update the local state first
      return Promise.resolve(pAdd)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async removeProductFromVuexCart({ commit, dispatch, state }, product) {

    const cart = [...state.productsInCart]
    const editedIndex = cart.indexOf(product)

    cart.splice(editedIndex, 1)
    commit('UPDATE_CART', cart);
    
    try {
     
      const { uid } = this.getters['auth/authUser']
    
      const dir = `users/${uid}/cart/${product.cartId}`

      if(!uid) return;
      const pDelete= await this.$fire.firestore
        .doc(dir)
        .delete()
      return Promise.resolve(pDelete)
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
      
    }

  },
  async clearUserCart({ commit, dispatch, state }){
    
    try {
     
      const { uid } = this.getters['auth/authUser']
    
      

      if(!uid) return commit('UPDATE_CART',[]);

      const dir = `users/${uid}/cart`;
      const cartDocs = await this.$fire.firestore.collection(dir).get();
      const cartRefs = cartDocs.docs.map(doc=>doc.ref);
      const batch = this.$fire.firestore.batch(); 

      cartRefs.forEach(ref=>{
        batch.delete(ref);
      })

      const pDelete = await batch.commit();

      commit('UPDATE_CART',[]);
      return Promise.resolve(pDelete)
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
      
    }
  },
}
