export const state = () => ({
  product: {},
})

export const getters = {
  selectedProduct: (state) => {
    return state.product
  },
}

export const mutations = {
  SET_PRODUCT(state, product) {
    state.product = product
  },
}

export const actions = {
  async getProductById({ commit, dispatch }, id) {
    try {
      const p = await this.$fire.firestore.collection('products').doc(id).get()

      if (!p.exists) throw new Error('No product found with that ID')

      const product = { ...p.data(), id: p.id }

      return Promise.resolve(product)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getProductFromDb({ commit, dispatch }, id) {
    try {
      const l = await this.$fire.firestore.collection('products').get()

      if (l.empty) throw new Error('No product found with that ID')
      const p = l.docs.pop()
      const product = { ...p.data(), id: p.id }

      return Promise.resolve(product)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getProductsBySubCategoryLinkName(
    { commit, dispatch },
    { category, subcategory }
  ) {
    if(!category)
      throw new Error ('Invalid category for firestore query - ' + category);

    if(!subcategory)
      throw new Error ('Invalid subcategory for firestore query - ' + subcategory)
    try {
      const p = await this.$fire.firestore
        .collection(`products`)
        .where('category', '==', category)
        .where('linkSubCategory', '==', subcategory)
        .where('visible', '==', true)
        .orderBy('priority', 'asc')
        .get()

      if (p.empty) return []

      const l = p.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })

      return l
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getProductFromDbWithLinkName({ commit, dispatch }, id) {
    try {
      if (!id) return

      const p = await this.$fire.firestore
        .collection(`products`)
        .where('linkName', '==', id)
        .get()

      if (p.empty) throw new Error('No product found for that URL')

      const l = p.docs.pop()
      const product = { ...l.data(), id: l.id }

      //get all
      const productOptions = await dispatch('getAllProductOptions', product.id)

      // add on options to product object
      product.options = [...productOptions]

      return product
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getAllProductOptions({ commit, dispatch }, id) {
    try {
      const optionsQuery = await this.$fire.firestore
        .collection(`products/${id}/options`)
        .get()

      if (optionsQuery.empty) return []

      const options = optionsQuery.docs.map((option) => {
        return {
          ...option.data(),
          productId: id,
          firestoreOptionId: option.id,
          /*  optionRef: option.ref, */ // This will give Cannot stringify arbitrary non-POJOs DocumentReference  when running npm run generate
        }
      })
      return options
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  },
  async getAllYearlyPlanners({ commit, dispatch }, id) {
    try {
      const p = await this.$fire.firestore
        .collection(`products`)
        .where('category', '==', 'Yearly Planners')
        .where('visible', '==', true)
        .orderBy('priority', 'asc')
        .get()

      if (p.empty) return []

      const l = p.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })

      return l
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getAllFeaturedProducts({ commit, dispatch }, id) {
    try {
      const numFeaturedProducts = 6
      const p = await this.$fire.firestore
        .collection('products')
        .where('isFeatured', '==', true)
        .where('visible', '==', true)
        .orderBy('priority', 'desc')
        .limit(numFeaturedProducts)
        .get()

      if (p.empty) return []

      const l = p.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })

      return l
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getProductCategory({ commit, dispatch }, category) {
    try {
      const p = await this.$fire.firestore
        .collection(`products`)
        .where('category', '==', category)
        .where('visible', '==', true)
        .orderBy('priority', 'asc')
        .get()

      if (p.empty) return []

      const l = p.docs.map((doc) => {
        return { ...doc.data(), id: doc.id }
      })

      return l
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
